<template>
  <div>
    <div class="holder">
      <div v-if="!nextslide">
        <img
          class="corporative-img"
          src="../../assets/icons/corporative.svg"
          alt="corporative"
        />

        <div class="body-holder">
          <h6>WELCOME TO SHELTA COOPERATIVE (SheltaCo)</h6>
          <h5>Thank you for your interest in joining Shelta Cooperative!</h5>
          <p>
            SheltaCo opens members to opportunities in the real estate and
            housing sector which includes property ownership and investment.
            SheltaCo is an affiliate of Shelta a property technology company
            focused on property management and financing. As a SheltaCo member,
            you can access real estate opportunities and investments
            opportunities after agreeing to become a member when you click on
            the Continue button below.
          </p>
          <h4>Why join Shelta Cooperative?</h4>
          <p>
            SheltaCo has a history of delivering great opportunities in the
            housing sector and excellent return on investments, backed by
            vibrant cooperative management team, sound financial management and
            strategic planning. As a cooperative, our focus is you—our valued
            member. We prioritize your interests and work tirelessly to ensure
            your real estate and financial goals are achieved. Rest assured,
            your investments are in safe hands. SheltaCo adheres to stringent
            security protocols and operates with full transparency.
          </p>
          <p>Our vision is to make living accessible and rewarding for all our members.</p>
          <div class="continue-btn">
            <button
              class="btn continue"
              type="submit"
              @click="nextslide = 'yes'"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div v-if="nextslide">
        <div class="termscondition">
          <h5>SHELTA MULTIPURPOSE COOPERATIVE SOCIETY LIMITED</h5>
          <h4>Terms and conditions</h4>
          <h6>
            <small class="terms-headers">ELIGIBILITY:</small>
            <p class="bullets">
              Individuals interested in joining Shelta Multipurpose Cooperative
              Society must meet the eligibility criteria set by the society.
            </p>
            <p class="bullets">
              Have the legal right(s) to the Account(s) and other financial
              information that may be created or accessed through the Service.
            </p>
            <p class="bullets">
              Are not fraudulent or involved in any illegal activity, including,
              but not limited to the impersonation of another person or entity.
            </p>
            <p class="bullets">
              Have not violated any law, statute, ordinance or regulation
              (including, but not limited to, those governing export control,
              consumer protection, unfair competition, anti-discrimination or
              false advertising).
            </p>
            <p class="bullets">
              Are above 18 years of age (legal adults) or personas old enough to
              make investment/financial decisions personally and people with
              verifiable personalities.
            </p>
          </h6>
          <h6>
            <small class="terms-headers">APPLICATION PROCESS:</small>
            <p class="bullets">
              Prospective members must complete the official membership
              application form.
            </p>
            <p class="bullets">
              Submission of accurate and truthful information is essential for
              the application process.
            </p>
            <p class="bullets">
              The application form can be obtained from www.myshelta.com, or by
              sending an email to cooperative@myshelta.com or by visiting our
              office address at 4 Amurie Omanze Street, Off Samuel Ladoke
              Akintola Boulevard, Garki 2, Federal Capital Territory, Abuja.
            </p>
          </h6>
          <h6>
            <small class="terms-headers">Membership Approval:</small>
            <p class="bullets">
              All applications are subject to approval by the Shelta
              Multipurpose Cooperative board.
            </p>
            <p class="bullets">
              The decision of the board regarding membership approval is final.
            </p>
          </h6>

          <h6>
            <small class="terms-headers">Membership Fees:</small>
            <p class="bullets">
              Members are required to pay the specified membership fee as
              determined by the society.
            </p>
            <p class="bullets">
              The membership fee is non-refundable.
            </p>
          </h6>

          <h6>
            <small class="terms-headers">Rights and Responsibilities:</small>
            <p class="bullets">
              Upon approval, you confirm that you have the legal right(s) to the
              Account(s) and other financial information that may be created or
              accessed via the Service. You represent and agree that all
              information you provide to us in connection with the Service is
              and will remain accurate, current and complete; and that you have
              the right to provide such information to us. You shall keep your
              account information up to date and accurate.
            </p>
            <p class="bullets">
              You accept responsibility for making sure that you understand how
              to properly use the applications and software required for any
              Service. In the event that we change or upgrade any Service, you
              are responsible for making sure that you understand how to use the
              same, as changed or upgraded. We will not be liable to you for any
              losses caused by your failure to properly use your Username,
              Password, any device or any other failure or inability to properly
              access the Service.
            </p>
            <p class="bullets">
              You shall comply with all instructions issued by the Cooperative
              from time to time regarding the use and operation of any Service
              you have chosen to access.
            </p>
            <p class="bullets">
              You are responsible for maintaining the confidentiality, security,
              and integrity of your Security Details.
            </p>
            <p class="bullets">
              If you request for or agree to any service as provided by the
              cooperative, you agree to have read, understood and shall be bound
              by the relevant Member guide(s) provided by the Cooperative from
              time to time (in any form and via any medium the Cooperative may
              choose) as well as any applicable Terms and Conditions as shall be
              determined by the Cooperative from time to time.
            </p>
            <p class="bullets">
              If you believe or should have known that your phone, Username,
              Password, or any other device required to access your Account via
              a Service has been lost, stolen or compromised, it is your
              responsibility to contact the service provider immediately to stop
              all wireless service. If you believe that someone may attempt to
              use your access to the Service without your consent, or has
              transferred money from your account without your permission,
              notify us immediately.
            </p>
            <p class="bullets">
              You agree not to use the Service or the content or information
              delivered through the Service in any way that would; infringe any
              third-party copyright, patent, trademark, trade secret, or other
              proprietary rights or rights of publicity or privacy, including
              any rights in any software deployed for rendering the Service, be
              fraudulent or involve any illegal activity, including, but not
              limited to the impersonation of another person or entity, violate
              any law, statute, ordinance or regulation (including, but not
              limited to, those governing export control, consumer protection,
              unfair competition, anti-discrimination or false advertising),
              create liability for us or our affiliates or service providers, or
              cause us to lose (in whole or in part) the services of any of our
              service providers, interfere with or disrupt computer networks
              connected to any Service.
            </p>
            <p class="bullets">
              You accept liability for any loss incurred or otherwise from any
              non-compliance with any of the precautions stated herein or
              otherwise issued by the Cooperative or any other reasonable
              precautions for the prevention of fraudulent access to your
              Account via the Service.
            </p>
            <p class="bullets">
              In the event that the Account upon which a Service is accessed is
              jointly owned, all your liabilities as contained in these Terms
              shall be joint and several.
            </p>
            <p class="bullets">
              You shall notify us immediately: if you believe your Security
              Details and/or device has been compromised or misused in any
              manner; of any disputed Transaction; you change your name,
              address, email address, phone numbers or any other personal
              details; of any other information which ordinarily and reasonably
              should be notified to us.
            </p>
            <p class="bullets">
              We have the right, at our sole discretion, to establish or change
              limits, services, products or features concerning the Service and
              any related services, temporarily or permanently, including but
              not limited to (i) the amount of storage space you have available
              through the Service at any time, and (ii) the number of times (and
              the maximum duration for which) you may access the Service in a
              given period of time. You may reject changes by discontinuing use
              of the Service and any related services to which such changes
              relate. Your continued use of the Service or any related services
              will constitute your acceptance of, and agreement to such changes.
            </p>

            <p class="bullets">
              Maintenance of the Service or any related services may be
              performed from time-to- time resulting in interrupted service,
              delays or errors in such Service or related services.
            </p>
            <p class="bullets">
              We may take any precautionary step deemed reasonable for the
              protection of the integrity of the Service via such measure(s)
              which may include, and are not limited to ending your log-in
              session after a determined period of inactivity.
            </p>
            <p class="bullets">
              We may refuse to effect a Transaction through the Service if: The
              Transaction exceeds the available funds in your account; or the
              Transaction does not comply with any applicable terms we shall
              stipulate from time to time; We reasonably determine that refusal
              to effect the Transaction is prudent under the circumstance.
            </p>
            <p class="bullets">
              If any establishment asks us for authorization before effecting
              payment via the Service, we may decide not to give our
              authorization if: We reasonably believe that your Security Details
              have been compromised; You are in breach of any clause of these
              terms.
            </p>
            <p class="bullets">
              We may notify you about any changes by: Advertising in the press,
              our website or any other means of public notification; Putting
              messages in your statements; or Sending you a separate written
              notice via email, SMS; or Any other means of communication that we
              may determine. You are encouraged to call the Cooperative if you
              have any queries, complaints or issues regarding the Service.
            </p>
            <p class="bullets">
              We may disclose information about your Account(s) or transactions
              involving your Account(s) to third parties if: The disclosure is
              necessary to complete a transaction that you have authorized or
              requested; The disclosure is necessary to verify the existence and
              condition of your Account to a third party, such as a credit
              bureau or merchant; The disclosure is necessary to comply with a
              governmental agency or court order; You gave us permission to make
              the disclosure; The disclosure is necessary to collect information
              for internal use, or the use of our service providers, agents or
              contractors; The disclosure involves a claim by or against us
              concerning a transaction involving your Account; The disclosure
              otherwise is required or permitted under any laws and/or
              regulations; The disclosure is deemed by the Cooperative as being
              necessary to be made.
            </p>
          </h6>

          <h6>
            <small class="terms-headers">Code of Conduct:</small>
            <p class="bullets">
              Members are expected to adhere to the ethical standards and
              principles set by Shelta Multipurpose Cooperative Society.
            </p>
            <p class="bullets">
              Any violation of the code of conduct may result in disciplinary
              action, including termination of membership.
            </p>
          </h6>

          <h6>
            <small class="terms-headers">Financial Contributions:</small>
            <p class="bullets">
              Members may be required to make financial contributions as
              determined by the society for specific projects or initiatives.
            </p>
            <p class="bullets">
              Contributions will be clearly communicated, and members are
              expected to fulfill their financial obligations promptly.
            </p>
          </h6>

          <h6>
            <small class="terms-headers">Termination of Membership:</small>
            <p class="bullets">
              Membership may be terminated for reasons such as a breach of the
              cooperative's Terms and Conditions, code of conduct, or
              non-payment of dues.
            </p>
            <p class="bullets">
              Members will be notified in writing of the reasons for
              termination.
            </p>
          </h6>
          <h6>
            <small class="terms-headers">Confidentiality:</small>
            <p class="bullets">
              Members agree to maintain the confidentiality of any sensitive
              information shared within the cooperative.
            </p>
          </h6>
          <h6>
            <small class="terms-headers"
              >Amendments to Terms and Conditions:</small
            > 
            <p class="bullets">
              Shelta Multipurpose Cooperative Society reserves the right to
              amend these terms and conditions as needed.
            </p>
            <p class="bullets">
              Members will be informed of any changes, and continued membership
              implies acceptance of the revised terms.
            </p>
          </h6>

          <div class="form-check form_check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              v-model="conditionterms"
            />
            <label class="form-check-label" for="flexCheckChecked">
              I have read, understood and agreed to Shelta’s terms and
              conditions
            </label>
            <h6 class="checkBoxx">{{ checkBox }}</h6>
          </div>

          <div class="invest-nowbtn">
            <button
              class="btn"
              @click.prevent="jointInvestment"
              :disabled="loading"
            >
              Become a member
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loading"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
export default {
  name: "InvestJoin",
  // props: {
  //   properties
  // },

  components: {},
  data() {
    return {
      nextslide: false,
      loading: false,
      checkBox: "",
      conditionterms: "",
      user: ""
    };
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
  },
  methods: {
    async jointInvestment() {
      this.loading = true;
      if (this.conditionterms === "") {
        this.loading = false;
        this.checkBox = "Thick the box to agree";
        return this.$store.commit(
          "setApiFailed",
          "Check the terms and condition"
        );
      }
      try {
        this.checkBox = "";
        await Api.postRequest(`joincooperative`, {
          userid: this.user.id
        })
          .then(res => {
            this.loading = false;
            console.log("Join res", res);
            if (res.data.success) {
              this.$store.commit("setApiSuccess", "Success");
              return this.$router.push({
                path: `/dashboard/investment-dashboard`
              });
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$off-white: #fafafa;
$primary: #0033ea;
$shelta-black: #000a2f;
$limeGreen: #2bc300;
$grey: #555c74;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

.holder {
  border-radius: 20px;
  background: $white;
  padding: 40px;
  margin: 40px 30px;
}
.body-holder {
  h6 {
    margin-top: 40px;
    color: $grey;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }
  h5 {
    color: $shelta-black;
    text-align: left;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  h4 {
    color: $grey;
    text-align: left;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  p {
    color: $grey;
    text-align: left;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .continue {
    border-radius: 4px;
    background: $primary;
    color: $white;
    width: 235px;
    height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    box-shadow: none;
    outline: 0;
  }
}
.continue-btn {
  margin-top: 40px;
  text-align: right;
}

.termscondition {
  h4 {
    color: $grey;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }
  h5 {
    color: $shelta-black;
    text-align: left;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  h6 {
    color: $grey;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .terms-headers {
    display: flex;
    text-align: left;
    font-size: 17.5px;
    font-weight: bold;
    word-break: break-all;
  }
  .bullets {
    display: list-item;
    list-style-type: square;
    list-style-position: inside;
  }
}

.form_check {
  margin: 19px 20px 19px 0px;
  text-align: start;
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-left: 8px;
    // line-height: 150px;
    color: $grey;
  }
  input {
    width: 18px;
    height: 18px;
  }
}
// form-check-input
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c6c6c;
}
.checkBoxx {
  color: #dc3545;
  font-family: Lato;
  text-align: left;
  padding-top: 0.5rem;
  // margin-bottom: 0;
}

.invest-nowbtn {
  text-align: right;
  margin-top: 40px;
  button {
    border-radius: 4px;
    background: $primary;
    color: $white;
    width: 235px;
    height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    box-shadow: none;
    outline: 0;
  }
}

@media screen and (max-width: 599px) {
  .holder {
    margin: 15px;
    padding: 40px 15px;
  }
  .corporative-img {
    width: 75%;
  }
  .form_check {
    margin: 19px 20px 19px 0px;
  }
  .continue-btn {
    text-align: center;
  }
  .invest-nowbtn {
    text-align: center;
  }
}
</style>
